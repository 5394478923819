<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import orderShow from "./show/show.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import Status from "@/helpers/general/status";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import currencyFilter from '@/helpers/filters/currencyFilter';

/**
 * Customers Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    orderShow,
    CustomTable,
    SelectAutocompleteField,
  },
  data() {
    return {
      showModalId:'order-show-modal',
      pageHeader:{
        title: "Pedidos",
        breadcrumbs: [
          {
            text: "Pedidos",
            href: "/orders",
            active: true
          }
        ],
      },
      order_status: Status.getList('orders'),
      table:{
        url:'/orders',
        params: {
          'with_trashed': 1,
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "external_id", label: "Pedido", sortable: true },
          { key: "store.name", label: "Tienda", sortable: true },
          { key: "order_billing.full_name", label: "Cliente", sortable: true },
          { key: "created_at", label: "Fecha", sortable: true },
          { key: "status", label: "Estado", sortable: true },
          { key: "total", label: "Total", sortable: true },
        ],
        formatted_fields:[
          'status',
          'total'
        ]
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    onSuccess(){
      this.$refs.customerTable.refresh();
    },
    formatter(field, item){
      switch(field){
        case 'status':
          return Status.getStatus({itemStatus: item?.status, type: 'orders', html: true});
        case 'total':
          return currencyFilter(item?.total);
        default:
          return '';
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <orderShow :modal-id="showModalId" :id="showSelectedItemId"></orderShow>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :formatted-fields="table.formatted_fields"
              :formatter="formatter"
              :params="table.params"
              ref="customerTable"
              :show-view="true"
              :show-edit="false"
              :showSoftDelete="false"
              @show="showViewModal"
              :show-modal-id="showModalId"
            >
              <template v-slot:filters="{ filters }">
                <div class="row">
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="external_id"># de pedido</label>
                        <input id="external_id" v-model="filters.external_id" type="text" class="form-control" placeholder="Ingresa un pedido para filtrar"/>
                    </div>
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="status">Estado</label>
                        <select v-model="filters.status" class="form-control" placeholder="Todos">
                            <option value="">Todos</option>
                            <option v-for="option in order_status" v-bind:value="option.id" :key="option.id">
                                {{ option.label }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="name">Tienda</label>
                        <SelectAutocompleteField :url="`/stores`" :multiple="true" :placeholder="'Todos'" v-model="filters.store_id"></SelectAutocompleteField>
                    </div>
                </div> 
              </template>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>